import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../common/Logo";
import Theme from "../../vars/ThemeOptions";

const Wrap = styled.div`
  border-top: ${({ footBorderThickness }) =>
      footBorderThickness ? footBorderThickness : "20px"}
    solid
    ${({ footBorderColor }) => (footBorderColor ? footBorderColor : "#13182F")};
  @media only screen and (max-width: 991px) {
    background: ${({ footMobileBg }) => (footMobileBg ? footMobileBg : "")};
  }
  position: relative;
  z-index: 500;
`;

const WrapCont = styled(Container)`
  .row {
    padding-top: 1rem;
    padding-bottom: 1rem;
    a,
    p {
      text-transform: uppercase;
      color: ${Theme.hex("primary")};
      font-size: 0.9rem;
      text-decoration: none;
    }
    a:hover {
      color: black;
    }
    p {
      margin-bottom: 0;
    }
    .centered {
      display: flex;
      justify-content: center;
      align-items: center;
      .phone-wrap {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .phoneUrl {
      justify-content: center !important;
    }
  }
`;

const Footer = ({
  footLogoColor,
  footBorderColor,
  footBorderThickness,
  footMobileBg
}) => {
  // console.log('footLogoColor in Footer: ', footLogoColor);
  const data = useStaticQuery(graphql`
    query {
      contentfulGlobal(title: { eq: "Company Info" }) {
        title
        url: shortText1
        phoneDen: shortText2
        phoneFC: shortText3
        address: shortText4
      }
    }
  `).contentfulGlobal;
  //console.log(data)
  const { url, phoneDen, phoneFC, address } = data ? data : null;
  const cleanPhoneDen = phoneDen.replace(/\./g, "");
  const cleanPhoneFC = phoneFC.replace(/\./g, "");
  const cleanUrl = url.slice(8);
  return (
    <Wrap
      footBorderThickness={footBorderThickness}
      footBorderColor={footBorderColor}
      footMobileBg={footMobileBg}>
      <WrapCont>
        <Row>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 3, order: 1 }}
            className="centered">
            <Logo footLogoColor={footLogoColor ? footLogoColor : "black"} />
          </Col>
          <Col
            xs={{ span: 12, order: 4 }}
            lg={{ span: 2, order: 2 }}
            className="centered">
            <div className="phone-wrap">
              <a href={`tel:` + cleanPhoneDen} target="_blank" rel="noreferrer">
                {phoneDen}
              </a>
              <a href={`tel:` + cleanPhoneFC} target="_blank" rel="noreferrer">
                {phoneFC}
              </a>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            lg={{ span: 2, order: 3 }}
            className="centered">
            <a href={url} target="_blank" rel="noreferrer">
              {cleanUrl}
            </a>
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 5, order: 4 }}
            className="centered">
            <p>{address}</p>
          </Col>
        </Row>
      </WrapCont>
    </Wrap>
  );
};

export default Footer;
