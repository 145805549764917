import React from "react"
import styled from "styled-components"
import { Container } from 'react-bootstrap'
import Logo from "../common/Logo"
import HeadCTA from "../common/HeadCTA"
import Theme from "../../vars/ThemeOptions"
import HeadSimpleCTA from "../common/HeadSimpleCTA"

const WrapCont = styled(Container)`
  // width: 100%;
  // padding: 1rem 10vw;
  position: relative;
  z-index: 10000;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const Header = ({ logoSlug, headLogoColor, headCtaStyle, headCtaColor, showCta, ctaRegion }) => {
  return (
    <WrapCont>
      <Logo logoSlug={logoSlug} headLogoColor={headLogoColor ? headLogoColor : "black"} />
      {showCta 
        ? headCtaStyle === "simple"
          ? <HeadSimpleCTA headCtaColor={headCtaColor ? headCtaColor : Theme.hex('primary')} ctaRegion={ctaRegion} />
          : <HeadCTA headCtaColor={headCtaColor ? headCtaColor : Theme.hex('primary')} ctaRegion={ctaRegion} />
        : null        
      }
    </WrapCont>
  )
}

export default Header
