import * as React from "react";
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components";
import Theme from "../../vars/ThemeOptions";

let Styled = {};
Styled.Wrap = styled.div`
  // margin-left: 2rem;
  height: 100%;

  a {
    color: ${({ headCtaColor }) =>
      headCtaColor ? headCtaColor : Theme.hex("primary")};
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    height: 100%;
    width: 600px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: ${({ headCtaColor }) =>
        headCtaColor ? Theme.hex("primary") : `white`};
    }
    h2,
    p {
      margin-bottom: 0;
      white-space: nowrap;
    }
    h2 {
      font-size: 1.5rem;
      letter-spacing: 1.3px;
    }
    p {
      font-size: 0.8rem;
      letter-spacing: 0.7px;
    }
    .left-text,
    .right-text {
      text-align: center;
    }
    .divider {
      height: 32px;
      width: 2px;
      background: ${Theme.hex("primary")};
      margin: 0 10px;
    }
  }
  @media only screen and (max-width: 750px) {
    margin-top: 1rem;
    a {
      width: 80vw;
      h2 {
        font-size: 3vw;
        letter-spacing: 1.3px;
      }
      p {
        font-size: 1.5vw;
        letter-spacing: 0.7px;
      }
    }
  }
`;

const HeadCTA = ({ headCtaColor, ctaRegion }) => {
  return (
    <Styled.Wrap headCtaColor={headCtaColor}>
      <a
        href={"https://www.coloradosidingrepair.com/schedule-appointment/"}
        target="_blank"
        rel="noreferrer noopener">
        <div className="left-text">
          <h2>FREE NO-PRESSURE QUOTE</h2>
          <p>Send us pics of your siding project via text or web</p>
        </div>
        <div className="divider" />
        <div className="right-text">
          <h2>
            {ctaRegion === "northern-colorado"
              ? "(970) 818-3050"
              : "(303) 323-8578"}
          </h2>
          <p>coloradosidingrepair.com</p>
        </div>
      </a>
    </Styled.Wrap>
  );
};

export default HeadCTA;
