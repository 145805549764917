import * as React from "react";
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components";
import Theme from "../../vars/ThemeOptions";

let Styled = {};
Styled.Wrap = styled.div`
  height: 100%;
  a {
    color: ${({ headCtaColor }) =>
      headCtaColor ? headCtaColor : Theme.hex("primary")};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    height: 100%;
    width: 600px;
    text-decoration: none;
    &:hover {
      color: ${Theme.hex("primary")};
    }
    p {
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }
  @media only screen and (max-width: 991px) {
    margin-top: 1rem;
    a {
      width: 80vw;
      justify-content: flex-start;
      text-decoration: none;
      p {
        font-size: 3vw;
        letter-spacing: 0.7px;
      }
    }
  }
`;

const HeadSimpleCTA = ({ headCtaColor, ctaRegion }) => {
  return (
    <Styled.Wrap headCtaColor={headCtaColor}>
      <a
        href={"https://www.coloradosidingrepair.com/schedule-appointment/"}
        target="_blank"
        rel="noreferrer noopener">
        <p>
          coloradosidingrepair.com &nbsp; &nbsp;{" "}
          {ctaRegion === "northern-colorado"
            ? "(970) 818-3050"
            : "(303) 323-8578"}
        </p>
      </a>
    </Styled.Wrap>
  );
};

export default HeadSimpleCTA;
