/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MainLayout } from "wmk-lib";

import Header from "./Header";
import Footer from "./Footer";

const Wrap = styled.div`
  @media only screen {
    *:focus,
    *:active {
      outline: none;
    }
  }
  overflow: hidden;
`;

type LayoutCompProps = {
  layoutProps: {
    logoSlug?: string;
    headLogoColor?: string;
    headCtaStyle?: string;
    headCtaColor?: string;
    showCta?: boolean;
    ctaRegion?: string;
    footLogoColor?: string;
    footBorderColor?: string;
    footBorderThickness?: string;
    footMobileBg?: string;
  };
  children: React.ReactNode;
};

const Layout = ({ layoutProps, children }: LayoutCompProps) => {
  const logoSlug = layoutProps.logoSlug;
  const headLogoColor = layoutProps.headLogoColor;
  const headCtaStyle = layoutProps.headCtaStyle;
  const headCtaColor = layoutProps.headCtaColor;
  const showCta = layoutProps.showCta;
  const ctaRegion = layoutProps.ctaRegion;
  const footLogoColor = layoutProps.footLogoColor;
  const footBorderColor = layoutProps.footBorderColor;
  const footBorderThickness = layoutProps.footBorderThickness;
  const footMobileBg = layoutProps.footMobileBg;

  return (
    <Wrap>
      <MainLayout
        Header={() => (
          <Header
            logoSlug={logoSlug}
            headLogoColor={headLogoColor}
            headCtaStyle={headCtaStyle}
            headCtaColor={headCtaColor}
            showCta={showCta}
            ctaRegion={ctaRegion}
          />
        )}
        Footer={() => (
          <Footer
            footLogoColor={footLogoColor}
            footBorderColor={footBorderColor}
            footBorderThickness={footBorderThickness}
            footMobileBg={footMobileBg}
          />
        )}>
        {children}
      </MainLayout>
    </Wrap>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layoutProps: PropTypes.object
};

Layout.defaultProps = {
  layoutProps: {}
};

export default Layout;
